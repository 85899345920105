import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'sa-footer',
    templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {
    currentDate = new Date();
    currentYear = this.currentDate.getFullYear();
    constructor() {
    }

    ngOnInit() {
    }

}
