import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'chat-widget',
    templateUrl: './chat-widget.component.html',
})
export class ChatWidgetComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
