import {Component, OnInit} from '@angular/core';
import {UserService} from '@app/core/services/user.service';
import {LayoutService} from '@app/core/services/layout.service';
import {Router} from "@angular/router";

@Component({

    selector: 'sa-login-info',
    templateUrl: './login-info.component.html',
})
export class LoginInfoComponent implements OnInit {

    currentUser: any = {};

    constructor(public us: UserService,
                private router: Router,
                private layoutService: LayoutService) {
    }

    ngOnInit() {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

    toggleShortcut() {
        this.layoutService.onShortcutToggle()
    }

    AdminProfile() {
        // this.router.navigate(['/profiles/createnew/1'])
    }

}
