import {Injectable} from "@angular/core";

import {BehaviorSubject} from "rxjs";
import {JsonApiService} from "@app/core/services/json-api.service";
import {Observable, of} from 'rxjs';
import {HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import {CommonService} from "@app/core/common/common.service";
import {map, catchError, tap} from 'rxjs/operators';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};


const defaultUser = {
    username: "Guest"
}

@Injectable()
export class UserService {
    public user$ = new BehaviorSubject({...defaultUser});
    url: any;

    constructor(private jsonApiService: JsonApiService,
                private http: HttpClient,
                private commonService: CommonService) {
        this.jsonApiService.fetch("/user/login-info.json").subscribe(this.user$);
        this.url = commonService.getApiUrl() + '/user/';
    }

    private extractData(res: Response) {
        let body = res;
        return body || {};
    }

    public logout() {
        localStorage.removeItem('currentUser');
        localStorage.clear();
        this.user$.next({...defaultUser})
    }


    getOneUser(id): Observable<any> {
        return this.http.get(this.url + 'getOne/' + id).pipe(
            map(this.extractData));
    }


    private handleError<T>(operation = 'operation', result?: any) {
        return (error: any): Observable<any> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            console.log(`${operation} failed: ${error.message}`);
            const errorData = {
                status: false,
                message: 'Server Error'
            };
            // Let the app keep running by returning an empty result.
            return of(errorData);
        };
    }
}
