import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-empty-layout',
    templateUrl: './empty-layout.component.html',
    styles: []
})
export class EmptyLayoutComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
