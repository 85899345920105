import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { MainLayoutComponent } from "./shared/layout/app-layouts/main-layout.component";
import { AuthLayoutComponent } from "./shared/layout/app-layouts/auth-layout.component";
import { Error404Component } from "@app/features/miscellaneous/error404/error404.component";

import { AuthGuard } from "@app/core/common/_guards/auth.guard";

const routes: Routes = [
  {
    path: "",
    redirectTo: "dashboard", //auth/login // commit by 226
    pathMatch: "full",
  },
  {
    path: "",
    component: MainLayoutComponent,
    data: { pageTitle: "Home" },
    children: [
      {
        path: "",
        redirectTo: "dashboard",
        pathMatch: "full",
      },
      {
        path: "dashboard",
        loadChildren: "./features/dashboard/dashboard.module#DashboardModule",
        data: { pageTitle: "Dashboard" },
        canActivate: [AuthGuard],
      },
      {
        path: "users",
        loadChildren: "./features/User/user.module#UserModule",
        data: { pageTitle: "User" },
        canActivate: [AuthGuard],
      },
      {
        path: "roles",
        loadChildren: "./features/Role/role.module#RoleModule",
        data: { pageTitle: "Role" },
        canActivate: [AuthGuard],
      },
      {
        path: "products",
        loadChildren: "./features/Product/product.module#ProductModule",
        data: { pageTitle: "Product" },
        canActivate: [AuthGuard],
      },
      {
        path: "inventorys",
        loadChildren: "./features/Inventory/Inventory.module#InventoryModule",
        data: { pageTitle: "Inventory" },
        canActivate: [AuthGuard],
      },
      {
        path: "reconciliation",
        loadChildren:
          "./features/Reconciliation/reconciliation.module#ReconciliationModule",
        data: { pageTitle: "Reconciliation" },
        canActivate: [AuthGuard],
      },
      {
        path: "dars",
        loadChildren: "./features/Dar/dar.module#DarModule",
        data: { pageTitle: "Dar" },
        canActivate: [AuthGuard],
      },
      {
        path: "orders",
        loadChildren: "./features/Order/order.module#OrderModule",
        data: { pageTitle: "Order" },
        canActivate: [AuthGuard],
      },
      {
        path: "budgets",
        loadChildren: "./features/Budget/budget.module#BudgetModule",
        data: { pageTitle: "Budget" },
        canActivate: [AuthGuard],
      },
      {
        path: "stn-detail",
        loadChildren: "./features/Stn/stn.module#StnModule",
        data: { pageTitle: "Stn Detail" },
        canActivate: [AuthGuard],
      },
      {
        path: "costlevels",
        loadChildren: "./features/CostLevel/costlevel.module#CostLevelModule",
        data: { pageTitle: "CostLevel" },
        canActivate: [AuthGuard],
      },
      {
        path: "customers",
        loadChildren: "./features/Customer/customer.module#CustomerModule",
        data: { pageTitle: "Customer" },
        canActivate: [AuthGuard],
      },
      {
        path: "reports",
        loadChildren: "./features/Report/report.module#ReportModule",
        data: { pageTitle: "Report" },
        canActivate: [AuthGuard],
      },
      {
        path: "teja-send-dashboard-file-on-server",
        loadChildren: "./features/Temp/temp.module#TempModule",
        data: { pageTitle: "Temp" },
        // canActivate: [AuthGuard]
      },
      {
        path: "help",
        loadChildren: "./features/Help/help.module#HelpModule",
        data: { pageTitle: "Help" },
        canActivate: [AuthGuard],
      },
    ],
  },

  {
    path: "auth",
    component: AuthLayoutComponent,
    loadChildren: "./features/auth/auth.module#AuthModule",
  },
  {
    path: "order-request/:UserID/:OrderID/:Token",
    loadChildren:
      "./features/OrderRequest/order-request.module#OrderRequestModule",
  },
  {
    path: "**",
    component: Error404Component,
    loadChildren:
      "./features/miscellaneous/miscellaneous.module#MiscellaneousModule",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
