﻿import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';

import {map} from 'rxjs/operators';
import {Router} from '@angular/router';

import {User} from '../_models/user';
import {CommonService} from '../common.service';
import {MatSnackBar} from "@angular/material";

@Injectable({providedIn: 'root'})
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    url: string;
    adminappUrl: string;

    constructor(private http: HttpClient,
                private commService: CommonService,
                private router: Router,
                private snackBar: MatSnackBar) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
        this.url = this.commService.getApiUrl();
        this.adminappUrl = this.commService.getAdminAppUrl();

    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(username: string, password: string) {
        return this.http.post<any>(this.url + `/admin/login`, {username: username, password: password})
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.currentUserSubject.next(user);
                }

                return user;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        localStorage.clear();
        this.currentUserSubject.next(null);
    }

    checkSelectedComponentVisiblity(menuName) {
        const allMenus = JSON.parse(localStorage.getItem('VisibleMenus'));
        let index = allMenus.findIndex(x => x.name === menuName);
        if (index === -1) {
            for (const row of allMenus) {
                if (row.children) {
                    index = row.children.findIndex(x => x.name === menuName);
                    if (index !== -1) {
                        break;
                    }
                }
            }
        }
        if (index === -1) {
            this.snackBar.open('This page is not accessible, Please contact to administrator', 'OK', {
                duration: 5000,
                panelClass: ['warning-snackbar'],
                verticalPosition: 'top'
            });
            this.router.navigate(['/dashboard/analytics']);
        }
    }

    forgot(useremail: string) {
        let adminappUrl = this.adminappUrl;
        return this.http.post<any>(this.url + `/admin/forgot`, {useremail, adminappUrl})
            .pipe(map(user => {
                //alert(user.status);
                if (user.status == true) {
                    // alert('Email Sent to your email ID so please check that and reset your password.')
                } else {
                    //alert('Something Wrong');
                }
                return user;
                //alert(JSON.stringify(user));
            }));
    }

    reset(UserPassword: string, token: string) {
        return this.http.post<any>(this.url + `/admin/reset`, {UserPassword, token})
            .pipe(map(user => {
                //alert(JSON.stringify(user));
                return user;
            }));
    }
}
