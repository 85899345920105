import { Component, OnInit, Inject } from '@angular/core';
import {Router, ActivatedRoute, Event, NavigationStart, NavigationEnd, NavigationError} from "@angular/router";
import { DOCUMENT } from '@angular/platform-browser';

declare var $: any;

@Component({
    selector: 'sa-header',
    templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
    currentUser = JSON.parse(localStorage.getItem('currentUser'));
    constructor(private router: Router, @Inject(DOCUMENT) private document: Document) {
        
    }

    ngOnInit() {
    }


    searchMobileActive = false;

    toggleSearchMobile() {
        this.searchMobileActive = !this.searchMobileActive;

        $('body').toggleClass('search-mobile', this.searchMobileActive);
    }

    onSubmit() {
        this.router.navigate(['/miscellaneous/search']);

    }

    onClickLogo(){
        this.router.navigate(['/dashboard']);
    }
}
