import { Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { LoginInfoComponent } from "../../user/login-info/login-info.component";
import { CommonService } from "@app/core/common/common.service";
import { LayoutService } from "@app/core/services/layout.service";

const MINUTES_UNITL_AUTO_LOGOUT = 15; // in mins
const CHECK_INTERVAL = 15000; // in ms
const STORE_KEY = "lastAction";

@Component({
  selector: "sa-navigation",
  templateUrl: "./navigation.component.html",
})
export class NavigationComponent implements OnInit {
  currentUser: any;
  NavItems: any = [];
  checkMenu = false;

  public getLastAction() {
    return parseInt(localStorage.getItem(STORE_KEY));
  }
  public setLastAction(lastAction: number) {
    localStorage.setItem(STORE_KEY, lastAction.toString());
  }

  constructor(
    private commonService: CommonService,
    public router: Router,
    private layoutService: LayoutService
  ) {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (
      this.currentUser &&
      this.currentUser.RoleID &&
      (this.currentUser.RoleID.Name == "Super Admin" ||
        this.currentUser.RoleID.Name == "OM Supervisor")
    ) {
      document.getElementById("webapp-title").innerText =
        "Stryker India | Demo tracking tool";
      this.NavItems = [
        {
          title: "Users",
          name: "Users",
          url: "/users",
          icon: "fa-user",
        },
        {
          title: "Roles",
          name: "Roles",
          url: "/roles",
          icon: "fa-group",
        },
        {
          title: "Products",
          name: "Products",
          url: "/products",
          icon: "fa-product-hunt",
        },
        {
          title: "Inventories",
          name: "Inventories",
          url: "/inventorys",
          icon: "fa-houzz",
        },
        {
          title: "Dar Inventories",
          name: "Dar Inventory",
          url: "/dars",
          icon: "fa-houzz",
        },
        {
          title: "Customers",
          name: "Customers",
          url: "/customers",
          icon: "fa-user",
        },
        // {
        //     title: 'Orders',
        //     name: 'Orders',
        //     url: '/orders',
        //     icon: 'fa-shopping-cart'
        // },
        {
          title: "Budgets",
          name: "Budgets",
          url: "/budgets",
          icon: "fa-money",
        },
        {
          title: "STN Detail",
          name: "STN Detail",
          url: "/stn-detail",
          icon: "fa-money",
        },
        {
          title: "Inventory Reconciliation",
          name: "Inventory Reconciliation",
          url: "/reconciliation",
          icon: "fa-houzz",
        },
        {
          title: "reports",
          name: "Reports",
          url: "",
          icon: "fa-bar-chart",
          children: [
            {
              title: "Trend Of Service",
              name: "Trend Of Service",
              url: "/reports/trend-service",
            },
            {
              title: "Budgets & Actual Wise",
              name: "Budgets & Actual Wise",
              url: "/reports/budgets",
            },
            {
              title: "BU & Franchise Wise",
              name: "BU & Franchise Wise",
              url: "/reports/franchise-buhead",
            },
            {
              title: "Orders",
              name: "Orders Wise",
              url: "/reports/orders",
            },
            {
              title: "products",
              name: "Products Wise",
              url: "/reports/products",
            },
            {
              title: "Quantity & Amount Wise",
              name: "Quantity & Amount Wise",
              url: "/reports/sort-order",
            },
            {
              title: "TAT Report",
              name: "TAT Report",
              url: "/reports/tat",
            },
            {
              title: "Active Order Detail",
              name: "Active Order Detail",
              url: "/reports/active-order",
            },
            {
              title: "Management Reporting",
              name: "Management Reporting",
              url: "/reports/management",
            },
            {
              title: "Leadership Reporting",
              name: "Leadership Reporting",
              url: "/reports/leadership",
            },
            {
              title: "C2C Products Holding",
              name: "C2C Products Holding",
              url: "/reports/c2c-products-holding-report",
            },
            {
              title: "No Customer Order's Report",
              name: "No Customer Order's Report",
              url: "/reports/nocustomer",
            },
          ],
        },
        {
                title: "help",
                name: "Help",
                url: "",
                icon: "fa-question-circle",
                children: [
                  {
                    title: "FAQ",
                    name: "FAQ",
                    url: "/help/faq",
                  },
                  {
                      title: 'user-manual',
                      name: 'User manual',
                      url: '/help/usermanual'
                  },
                  {
                      title: 'flow-chart',
                      name: 'Flow Chart',
                      url: '/help/flowchart'
                  }
                ],
          }
      ];
    } else if (
      this.currentUser &&
      this.currentUser.RoleID &&
      this.currentUser.RoleID.Description == "Salesperson"
    ) {
      document.getElementById("webapp-title").innerText =
        "Stryker India | Demo tracking tool";
        console.log(this.currentUser);
        
        //if(this.currentUser.Region == 'North'){ // commented bcoz now all regions will access new features only
          if(this.currentUser.lockAccount == true){
            this.NavItems = [
            {
              title: "Return Order",
              name: "Return Order",
              url: "/orders/returnform",
              icon: "fa-undo",
            },
            {
              title: "Transfer Order S2S",
              name: "Transfer Order S2S",
              url: "/orders/transferform",
              icon: "fa-exchange",
            },
            {
              //commit by 226
              title: "Transfer Order C2C",
              name: "Transfer Order C2C",
              url: "/orders/transferform-c2c",
              icon: "fa-exchange",
            },
            {
              title: "Acknowledge Order",
              name: "Acknowledge Order",
              url: "/orders/view/1",
              icon: "fa-check-circle",
            },
            { //code start by 127
              title: "Workflow for deviation", 
              name: "Workflow deviation",
              url: "/orders/deviation",
              icon: "fa-check-circle",
            }, //code end by 127
            {
              title: "reports",
              name: "Reports",
              url: "",
              icon: "fa-bar-chart",
              children: [
                {
                  title: "Active Order Detail",
                  name: "Active Order Detail",
                  url: "/reports/active-order",
                },
                // {
                //     title: 'Detailed Order Detail',
                //     name: 'Detailed Order Detail',
                //     url: '/reports/detailed-order'
                // }
              ],
            },
            {
                title: "help",
                name: "Help",
                url: "",
                icon: "fa-question-circle",
                children: [
                  {
                    title: "FAQ",
                    name: "FAQ",
                    url: "/help/faq",
                  },
                  {
                      title: 'user-manual',
                      name: 'User manual',
                      url: '/help/usermanual'
                  },
                  {
                      title: 'flow-chart',
                      name: 'Flow Chart',
                      url: '/help/flowchart'
                  }
                ],
            }
          ];
          } else {
          this.NavItems = [
            {
              title: "Create Order",
              name: "Create Order",
              url: "/orders/form/-1",
              icon: "fa-plus-square",
            },
            {
              title: "Return Order",
              name: "Return Order",
              url: "/orders/returnform",
              icon: "fa-undo",
            },
            {
              title: "Transfer Order S2S",
              name: "Transfer Order S2S",
              url: "/orders/transferform",
              icon: "fa-exchange",
            },
            {
              //commit by 226
              title: "Transfer Order C2C",
              name: "Transfer Order C2C",
              url: "/orders/transferform-c2c",
              icon: "fa-exchange",
            },
            {
              title: "Acknowledge Order",
              name: "Acknowledge Order",
              url: "/orders/view/1",
              icon: "fa-check-circle",
            },
            { //code start by 127
              title: "Workflow for deviation", 
              name: "Workflow deviation",
              url: "/orders/deviation",
              icon: "fa-check-circle",
            }, //code end by 127
            {
              title: "reports",
              name: "Reports",
              url: "",
              icon: "fa-bar-chart",
              children: [
                {
                  title: "Active Order Detail",
                  name: "Active Order Detail",
                  url: "/reports/active-order",
                }
                
              ],
            },
            {
                title: "help",
                name: "Help",
                url: "",
                icon: "fa-question-circle",
                children: [
                  {
                    title: "FAQ",
                    name: "FAQ",
                    url: "/help/faq",
                  },
                  {
                      title: 'user-manual',
                      name: 'User manual',
                      url: '/help/usermanual'
                  },
                  {
                      title: 'flow-chart',
                      name: 'Flow Chart',
                      url: '/help/flowchart'
                  }
                ],
              }
          ];
          }
        //} 
        
        // else {
        //   if(this.currentUser.lockAccount == true){
        //     this.NavItems = [
        //     {
        //       title: "Return Order",
        //       name: "Return Order",
        //       url: "/orders/returnform",
        //       icon: "fa-undo",
        //     },
        //     {
        //       title: "Transfer Order",
        //       name: "Transfer Order",
        //       url: "/orders/transferform2",
        //       icon: "fa-exchange",
        //     },
        //     {
        //       title: "Acknowledge Order",
        //       name: "Acknowledge Order",
        //       url: "/orders/view/1",
        //       icon: "fa-check-circle",
        //     },
        //     {
        //       title: "reports",
        //       name: "Reports",
        //       url: "",
        //       icon: "fa-bar-chart",
        //       children: [
        //         {
        //           title: "Active Order Detail",
        //           name: "Active Order Detail",
        //           url: "/reports/active-order",
        //         },
        //       ],
        //     },
        //     {
        //         title: "help",
        //         name: "Help",
        //         url: "",
        //         icon: "fa-question-circle",
        //         children: [
        //           {
        //             title: "FAQ",
        //             name: "FAQ",
        //             url: "/help/faq",
        //           },
        //           {
        //               title: 'user-manual',
        //               name: 'User manual',
        //               url: '/help/usermanual'
        //           },
        //           {
        //               title: 'flow-chart',
        //               name: 'Flow Chart',
        //               url: '/help/flowchart'
        //           }
        //         ],
        //       }
        //   ];
        //   } else {
        //     this.NavItems = [
        //       {
        //         title: "Create Order",
        //         name: "Create Order",
        //         url: "/orders/form2/-1",
        //         icon: "fa-plus-square",
        //       },
        //       {
        //         title: "Return Order",
        //         name: "Return Order",
        //         url: "/orders/returnform",
        //         icon: "fa-undo",
        //       },
        //       {
        //         title: "Transfer Order",
        //         name: "Transfer Order",
        //         url: "/orders/transferform2",
        //         icon: "fa-exchange",
        //       },
        //       {
        //         title: "Acknowledge Order",
        //         name: "Acknowledge Order",
        //         url: "/orders/view/1",
        //         icon: "fa-check-circle",
        //       },
        //       {
        //         title: "reports",
        //         name: "Reports",
        //         url: "",
        //         icon: "fa-bar-chart",
        //         children: [
        //           {
        //             title: "Active Order Detail",
        //             name: "Active Order Detail",
        //             url: "/reports/active-order",
        //           },
        //           // {
        //           //     title: 'Detailed Order Detail',
        //           //     name: 'Detailed Order Detail',
        //           //     url: '/reports/detailed-order'
        //           // }
        //         ],
        //       },
        //       {
        //         title: "help",
        //         name: "Help",
        //         url: "",
        //         icon: "fa-question-circle",
        //         children: [
        //           {
        //             title: "FAQ",
        //             name: "FAQ",
        //             url: "/help/faq",
        //           },
        //           {
        //               title: 'user-manual',
        //               name: 'User manual',
        //               url: '/help/usermanual'
        //           },
        //           {
        //               title: 'flow-chart',
        //               name: 'Flow Chart',
        //               url: '/help/flowchart'
        //           }
        //         ],
        //       }
        //     ];
        //   }
        // }
      
    } else if (
      this.currentUser &&
      this.currentUser.RoleID &&
      this.currentUser.RoleID.Description == "DealerChild"
    ) {
      document.getElementById("webapp-title").innerText =
        "Stryker India | Instrument tracking tool";
      this.NavItems = [
        {
          title: "Create Order",
          name: "Create Order",
          url: "/orders/form/-1",
          icon: "fa-plus-square",
        },
        {
                title: "help",
                name: "Help",
                url: "",
                icon: "fa-question-circle",
                children: [
                  {
                    title: "FAQ",
                    name: "FAQ",
                    url: "/help/faq",
                  },
                  {
                      title: 'user-manual',
                      name: 'User manual',
                      url: '/help/usermanual'
                  },
                  {
                      title: 'flow-chart',
                      name: 'Flow Chart',
                      url: '/help/flowchart'
                  }
                ],
            }
      ];
    } else if (
      this.currentUser &&
      this.currentUser.RoleID &&
      this.currentUser.RoleID.Description == "OM Supervisory"
    ) {
      document.getElementById("webapp-title").innerText =
        "Stryker India | Instrument tracking tool";
      this.NavItems = [
        {
          title: "Create Order",
          name: "Create Order",
          url: "/orders/form/-1",
          icon: "fa-plus-square",
        },
        {
          title: "reports",
          name: "Reports",
          url: "",
          icon: "fa-bar-chart",
          children: [
            {
              title: "Active Order Detail",
              name: "Active Order Detail",
              url: "/reports/active-order",
            },
            // {
            //     title: 'Detailed Order Detail',
            //     name: 'Detailed Order Detail',
            //     url: '/reports/detailed-order'
            // }
          ],
        },
        {
                title: "help",
                name: "Help",
                url: "",
                icon: "fa-question-circle",
                children: [
                  {
                    title: "FAQ",
                    name: "FAQ",
                    url: "/help/faq",
                  },
                  {
                      title: 'user-manual',
                      name: 'User manual',
                      url: '/help/usermanual'
                  },
                  {
                      title: 'flow-chart',
                      name: 'Flow Chart',
                      url: '/help/flowchart'
                  }
                ],
            }
      ];
    } else if (
      this.currentUser &&
      this.currentUser.RoleID &&
      this.currentUser.RoleID.Description == "Report"
    ) {
      document.getElementById("webapp-title").innerText =
        "Stryker India | Instrument tracking tool";
      this.NavItems = [
        {
          title: "reports",
          name: "Reports",
          url: "",
          icon: "fa-bar-chart",
          children: [
            {
              title: "Trend Of Service",
              name: "Trend Of Service",
              url: "/reports/trend-service",
            },
            {
              title: "Budgets & Actual Wise",
              name: "Budgets & Actual Wise",
              url: "/reports/budgets",
            },
            {
              title: "BU & Franchise Wise",
              name: "BU & Franchise Wise",
              url: "/reports/franchise-buhead",
            },
            {
              title: "Orders",
              name: "Orders Wise",
              url: "/reports/orders",
            },
            {
              title: "products",
              name: "Products Wise",
              url: "/reports/products",
            },
            {
              title: "Quantity & Amount Wise",
              name: "Quantity & Amount Wise",
              url: "/reports/sort-order",
            },
            {
              title: "TAT Report",
              name: "TAT Report",
              url: "/reports/tat",
            },
            {
              title: "Active Order Detail",
              name: "Active Order Detail",
              url: "/reports/active-order",
            },
            // {
            //     title: 'Detailed Order Detail',
            //     name: 'Detailed Order Detail',
            //     url: '/reports/detailed-order'
            // }
          ],
        },
        {
                title: "help",
                name: "Help",
                url: "",
                icon: "fa-question-circle",
                children: [
                  {
                    title: "FAQ",
                    name: "FAQ",
                    url: "/help/faq",
                  },
                  {
                      title: 'user-manual',
                      name: 'User manual',
                      url: '/help/usermanual'
                  },
                  {
                      title: 'flow-chart',
                      name: 'Flow Chart',
                      url: '/help/flowchart'
                  }
                ],
            }
      ];
    }
    this.checkMenu = true;
  }

  ngOnInit() {
    this.check();
    this.initListener();
    this.initInterval();
  }

  onClickMenu() {
    if (window.innerWidth < 768) {
      this.layoutService.onCollapseMenu();
    }
  }

  initListener() {
    document.body.addEventListener("click", () => this.reset());
    document.body.addEventListener("mouseover", () => this.reset());
    document.body.addEventListener("mouseout", () => this.reset());
    document.body.addEventListener("keydown", () => this.reset());
    document.body.addEventListener("keyup", () => this.reset());
    document.body.addEventListener("keypress", () => this.reset());
  }

  reset() {
    this.setLastAction(Date.now());
  }

  initInterval() {
    setInterval(() => {
      this.check();
    }, CHECK_INTERVAL);
  }

  check() {
    const now = Date.now();
    const timeleft =
      this.getLastAction() + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
    const diff = timeleft - now;
    const isTimeout = diff < 0;
    // if (isTimeout && this.auth.loggedIn)
    if (isTimeout) {
      // alert('logout');
      localStorage.clear();
      this.router.navigate(["./auth/login"]);
    }
  }
}
