export const languages: any = [
    {
        "key": "us",
        "alt": "United States",
        "title": "English (US)"
    },
    {
        "key": "fr",
        "alt": "France",
        "title": "Français"
    },
    {
        "key": "es",
        "alt": "Spanish",
        "title": "Español"
    },
    {
        "key": "de",
        "alt": "German",
        "title": "Deutsch"
    },
    {
        "key": "jp",
        "alt": "Japan",
        "title": "日本語"
    },
    {
        "key": "cn",
        "alt": "China",
        "title": "中文"
    },
    {
        "key": "zh",
        "alt": "Chinese",
        "title": "汉语/漢語"
    },
    {
        "key": "pt",
        "alt": "Portugal",
        "title": "Portugal"
    },
    {
        "key": "ru",
        "alt": "Russia",
        "title": "Русский язык"
    },
    {
        "key": "kr",
        "alt": "Korea",
        "title": "한국어"
    }
];
